import { A, H2 } from '@expo/html-elements';
import { TextProps } from '@expo/html-elements/build/primitives/Text';
import React from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { HoverableLink } from '../components/common/HoverableLink';
import { PrimaryButton } from '../components/common/PrimaryButton';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { SalonSignup } from '../components/salon/SalonSignup';
import { smBreakpoint } from '../constants';
import { useDimensions } from '../context/DimensionsContext';
import { useThemeState } from '../context/ThemeContext';
import {
  blackBrand,
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
  redBrand,
  TrailersDemiBold,
  wineBrand,
} from '../libs/constants';

interface Props { }

export const MenuScreen: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    header: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: 'uppercase',
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 32 : 48,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 24 : width < mdbreakpoint ? 34 : 50,
    },
    subHeader: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: 'uppercase',
      fontSize: width < smBreakpoint ? 18 : width < mdbreakpoint ? 28 : 40,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 20 : width < mdbreakpoint ? 32 : 44,
    },
    sectionHeader: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: 'uppercase',
      fontSize: width < smBreakpoint ? 18 : width < mdbreakpoint ? 34 : 40,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 20 : width < mdbreakpoint ? 38 : 44,
    },
    brandName: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      fontSize: width < smBreakpoint ? 18 : width < mdbreakpoint ? 24 : 30,
      letterSpacing: 1.02,
      marginTop: 10,
    },
    flavor: {
      fontFamily: GTAmerica,
      fontWeight: '500',
      fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
    },
    flavorDescription: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
    },
  });
  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <CuratorHeader />
      {/* <Image
        resizeMode="contain"
        source={{ uri: 'https://49market.s3.amazonaws.com/art_cafe_1.png' }}
        style={{
          width: width,
          height: width / 1.333,
          maxWidth: 1560,
          maxHeight: 1170,
          alignSelf: 'center',
        }}
      /> */}
      <View style={{ height: 40 }} />
      <View
        style={{
          justifyContent: 'center',
          width: 700,
          maxWidth: '90vw',
          alignSelf: 'center',
        }}
      >
        <Text style={styles.header}>Drinks & Snacks</Text>
        <Text
          style={{
            fontFamily: GTAmerica,
            fontWeight: '300',
            color: theme.foregroundColor,
            fontSize:
              width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
            letterSpacing: 1.07,
          }}
        >
          a veritable smorgasbord of our favorite beverages and treats
        </Text>
        <View style={{ height: 20 }} />

        <View style={{ height: 20 }} />
        <Text style={[styles.secondaryHeader]}>Drinks</Text>
        <View style={{ height: 20 }} />
        <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: width < mdbreakpoint ? 10 : 20,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>CBD</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Dram</Text>
            <Text style={[styles.text, styles.brandName]}>$5</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.dramapothecary.com/product/sweetgrass-adaptogen-cbd-sparkling-water/'
            }
          >
            <Text style={[styles.text, styles.flavor]}>Sweetgrass</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            The taste of this beverage is like the smell of the Colorado plains
            after a rainstorm. Fresh, invigorating, calming and earthy.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.dramapothecary.com/product/gingergrass-adaptogen-cbd-sparkling-water/'
            }
          >
            <Text style={[styles.text, styles.flavor]}>Gingergrass</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            A cheerful and aromatic sparkling water with a blend of adaptogenic
            herbs, stimulating ginger and Colorado grown hemp CBD
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.dramapothecary.com/product/beauty-bubbles-adaptogenic-cbd-sparkling-water/'
            }
          >
            <Text style={[styles.text, styles.flavor]}>Beauty Bubbles</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Light, bubbly and refreshing with a dreamy combination of Bilberry
            and Rose Petal. This drink is packed with ingredients to support
            healthy skin and hair including: COQ10, Silver Ear Mushroom, Moringa
            Leaf, Bilberry, Red Raspberry Leaf, Amla Berry, Pretty Face Flower
            Essence, and Rose Water. flavor!
          </Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Cann</Text>
            <Text style={[styles.text, styles.brandName]}>$6</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.cloudwaterbrands.com/collections/cloud-water/products/blood-orange-coconut'
            }
          >
            <Text style={[styles.text, styles.flavor]}>
              Blood Orange & Coconut
            </Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Blood Orange & Coconut + CBD is your tropical oasis in a bottle. The
            perfect combination of sweet and beautifully colored blood oranges
            with tropical, creamy coconut - the gift that keeps on giving.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.cloudwaterbrands.com/collections/cloud-water/products/blackberry-lemon-rosemary'
            }
          >
            <Text style={[styles.text, styles.flavor]}>
              Blackberry Lemon & Rosemary
            </Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            One sip of Blackberry Lemon & Rosemary + CBD feels like taking a
            hike through an off-road wooden path and stumbling upon wild
            blackberry bushes. Balanced with tangy lemon and a hint of organic
            wildflower honey and aromatic shrubs, Blackberry Lemon & Rosemary
            Cloud Water captures the essence of summer.
          </Text>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: width < mdbreakpoint ? 10 : 20,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>THC</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Cann</Text>
            <Text style={[styles.text, styles.brandName]}>$6</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://drinkcann.com/product/the-hi-boy?flavor=Blood+Orange+Cardamom&pack+size=8-pack'
            }
          >
            <Text style={[styles.text, styles.flavor]}>
              Blood Orange Cardamom
            </Text>
          </HoverableLink>

          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://drinkcann.com/product/the-hi-boy?flavor=Lemon+Lavender&pack+size=8-pack'
            }
          >
            <Text style={[styles.text, styles.flavor]}>
              Lemon Lavendar
            </Text>
          </HoverableLink>

          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://drinkcann.com/product/the-hi-boy?flavor=Grapefruit+Rosemary&pack+size=8-pack'
            }
          >
            <Text style={[styles.text, styles.flavor]}>
              Grapefruit Rosemary
            </Text>
          </HoverableLink>

        </View>

        <View style={{ height: 40 }} />

        {/* <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: width < mdbreakpoint ? 10 : 20,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>Psychadelics</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>
              Psychedelic Water
            </Text>
            <Text style={[styles.text, styles.brandName]}>$5</Text>
          </View>
          <Text style={[styles.text, styles.flavorDescription]}>
            Kava root has been used for centuries as a social drink to promote
            feelings of euphoria, this mood-boosting psychedelic root melts away
            stress so you can feel calm, clear-headed and happy.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://psychedelicwater.com/products/oolong-orange-blossom-6-pack'
            }
          >
            <Text style={[styles.text, styles.flavor]}>
              Oolong & Orange Blossom
            </Text>
          </HoverableLink>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://psychedelicwater.com/products/prickly-pear'}
          >
            <Text style={[styles.text, styles.flavor]}>Prickly Pear</Text>
          </HoverableLink>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://psychedelicwater.com/products/psychedelic-water-blackberry-yuzu-6-pack'
            }
          >
            <Text style={[styles.text, styles.flavor]}>Blackberry & Yuzu</Text>
          </HoverableLink>
        </View>

        <View style={{ height: 40 }} /> */}

        <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: 10,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>
            Non-Alcoholic Aperatifs
          </Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Ghia</Text>
            <Text style={[styles.text, styles.brandName]}>$5</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://drinkghia.com/products/le-spritz'}
          >
            <Text style={[styles.text, styles.flavor]}>Ghia Soda</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            A classic bitter aperitivo. Notes of rosemary and a twist of yuzu
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://drinkghia.com/products/le-spritz'}
          >
            <Text style={[styles.text, styles.flavor]}>Ghia Ginger</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            A touch of sweetness + the slow burn of ginger
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://drinkghia.com/products/le-spritz'}
          >
            <Text style={[styles.text, styles.flavor]}>Lime & Salt</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Fresh lime and a dash of sea salt
          </Text>
          <View style={{ height: 30 }} />
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Kin Euphorics</Text>
            <Text style={[styles.text, styles.brandName]}>$5</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://www.kineuphorics.com/products/actual-sunshine'}
          >
            <Text style={[styles.text, styles.flavor]}>Actual Sunshine</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Rise and glow from within. Our Sunshine is non-alcoholic and infused
            with adaptogens, nootropics, and vitamins such as C and D, Zinc,
            Saffron, and Turmeric for a super boost of immunity. Collagen
            brightens complexion while Coffeeberry® gently caffeinates for a
            bright-eyed morning.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://www.kineuphorics.com/products/kin-bloom'}
          >
            <Text style={[styles.text, styles.flavor]}>Bloom</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Taste the summer of love. Non-alcoholic Kin Bloom is infused with
            adaptogens, nootropics, and botanics like Schisandra, Damiana, and
            L-Theanine to awaken passion, conjure a calmer mind, and unlock
            heart-opening joy any time of day.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://www.kineuphorics.com/products/kin-spritz'}
          >
            <Text style={[styles.text, styles.flavor]}>Spritz</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Like a hit of sunshine for your mind, non-alcoholic, gently
            caffeinated Kin Spritz is infused with adaptogens, nootropics, and
            botanics like Rhodiola Rosea, 5-HTP, and Gaba to elevate your mood,
            smooth out stress, and offer a boost of energy.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://www.kineuphorics.com/products/lightwave'}
          >
            <Text style={[styles.text, styles.flavor]}>Lightwave</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Like naked forest bathing at midnight. Non-alcoholic,
            non-caffeinated Lightwave is infused with adaptogens, nootropics,
            and botanics like Reishi Mushroom, L-Theanine, and L-Tryptophan to
            mellow your mind, balance reactions to stress, and help conjure
            inner peace.
          </Text>
        </View>

        <View style={{ height: 40 }} />
        <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: 20,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>
            Sparkling Water
          </Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Olipop</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://drinkolipop.com/products/cream-soda'}
          >
            <Text style={[styles.text, styles.flavor]}>Cream Soda</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Better than spooning fluffy, sweet marshmallow cream straight out of
            the jar, Olipop's Cream Soda blends irresistibly decadent natural
            vanilla flavors with premium pure vanilla extract for a sumptuous,
            smooth soda you'll savor.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://drinkolipop.com/products/orange-squeeze'}
          >
            <Text style={[styles.text, styles.flavor]}>Orange Squeeze</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Orange Squeeze reinvents orange soda with a citrusy, vitamin C rich
            blend of natural ingredients. Clementine and mandarin juices collide
            with a hint of lemon to produce a bright twist on the nostalgic
            classic.
          </Text>
          <View style={{ height: 30 }} />
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Sanzo</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <Text style={[styles.text, styles.flavor]}>Yuzu & Ginger</Text>
          <Text style={[styles.text, styles.flavor]}>Calamansi</Text>
          <Text style={[styles.text, styles.flavor]}>Mango</Text>
          <View style={{ height: 30 }} />
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Dram</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.dramapothecary.com/product/citrus-sparkling-water-12-pack/'
            }
          >
            <Text style={[styles.text, styles.flavor]}>Citrus & Blossoms</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Crafted with organic citrus peels and delicate orange blossoms- this
            delicious sparkling water evokes a warm sunny day spent in a
            flowering orange grove.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.dramapothecary.com/product/lavender-lemon-balm-sparkling-water-12-pack/'
            }
          >
            <Text style={[styles.text, styles.flavor]}>
              Lavender & Lemon Balm
            </Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            A mood mellowing sparkling water with floral notes of Colorado grown
            lavender flowers paired with the uplifting citrusy aroma of lemon
            balm.
          </Text>
          <HoverableLink
            linkType={'web'}
            linkUrl={
              'https://www.dramapothecary.com/product/holy-basil-lemon-adaptogenic-sparkling-water-12pack/'
            }
          >
            <Text style={[styles.text, styles.flavor]}>Holy Basil & Lemon</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            An adaptogenic sparkling water crafted with organic Holy Basil aka
            Tulsi, which has a complex flavor profile of Clove, Mint and a touch
            of Basil sweetness. We’ve added a dash of Lemon Peel for brightness,
            and Passionflower to calm the nerves.
          </Text>
          <View style={{ height: 30 }} />
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>San Pellegrino</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <Text style={[styles.text, styles.flavor]}>
            Classic Sparkling Water
          </Text>
        </View>

        <View style={{ height: 40 }} />
        <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: 10,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>Tea</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Ruby</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>

          <HoverableLink
            linkType={'web'}
            linkUrl={'https://ruby.fun/collections/all'}
          >
            <Text style={[styles.text, styles.flavor]}>
              Sparkling Blood Orange
            </Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Refreshing, citrus and entrancing, with the flavor of an Italian
            soda.
          </Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Joe Tea</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <Text style={[styles.text, styles.flavor]}>Lemon Tea</Text>
          <Text style={[styles.text, styles.flavor]}>
            Half Tea & Half Lemonade
          </Text>
          <Text style={[styles.text, styles.flavor]}>Peach</Text>
        </View>
        <View style={{ height: 40 }} />
        <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: 10,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>Caffeine</Text>
          {/* <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Club-Mate</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <HoverableLink
            linkType={'web'}
            linkUrl={'https://club-mate.com/products/'}
          >
            <Text style={[styles.text, styles.flavor]}>Original</Text>
          </HoverableLink>
          <Text style={[styles.text, styles.flavorDescription]}>
            Berlin's most famous soft drink. Carbonated and made from yerba
            mate, this tea is the perfect pick-me-up.
          </Text> */}

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Rise</Text>
            <Text style={[styles.text, styles.brandName]}>$5</Text>
          </View>
          {/* <Text style={[styles.text, styles.flavor]}>
            Original Black Nitro Cold Brew
          </Text> */}
          <Text style={[styles.text, styles.flavor]}>Oat Milk Latte</Text>
          <Text style={[styles.text, styles.flavor]}>Oat Milk Mocha Latte</Text>
          <Text style={[styles.text, styles.flavor]}>
            Oat Milk Vanilla Latte
          </Text>
        </View>
        <View style={{ height: 40 }} />
        <View
          style={{
            borderWidth: 1,
            borderColor: theme.foregroundColor,
            padding: 10,
          }}
        >
          <Text style={[styles.text, styles.sectionHeader]}>Other</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Fever Tree</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <HoverableLink linkType={'web'} linkUrl={''}>
            <Text style={[styles.text, styles.flavor]}>Ginger Beer</Text>
          </HoverableLink>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>GT's</Text>
            <Text style={[styles.text, styles.brandName]}>$5</Text>
          </View>
          <Text style={[styles.text, styles.flavor]}>Synergy Trilogy</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>
              Harmless Harvest
            </Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <Text style={[styles.text, styles.flavor]}>Coconut Water</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Joe Tea</Text>
            <Text style={[styles.text, styles.brandName]}>$4</Text>
          </View>
          <Text style={[styles.text, styles.flavor]}>Lemonade</Text>
          {/* <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styles.text, styles.brandName]}>Cheerwine</Text>
            <Text style={[styles.text, styles.brandName]}>$3</Text>
          </View> */}
        </View>
        <View style={{ height: 60 }} />
      </View>
      <View style={{ height: 60 }} />
      <SalonSignup />
    </ScrollView>
  );
};
