import React, { useState } from "react";
import { ScrollView, StyleSheet, View, Text } from "react-native";
import { B, H1, H2, H3, LI, P } from "@expo/html-elements";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { smBreakpoint, lgBreakpoint } from "../constants";
import { useThemeState } from "../context/ThemeContext";
import {
  GTAmericaCompressed,
  mdbreakpoint,
  GTAmerica,
  PanamaBold,
} from "../libs/constants";
import { useDimensions } from "../context/DimensionsContext";
import { TouchableOpacity } from "../components/common/TouchableOpacity";
import { ImageCarousel } from "../components/common/ImageCarousel";
import { TextureUvs } from "pixi.js";

interface Props { }

export const PotteryScreen: React.FC<Props> = () => {
  document.title = "The Pottery | Happy Medium";

  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: "uppercase",
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: "300",
      fontSize: width < smBreakpoint ? 16 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.07,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    header: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: "uppercase",
      alignSelf: "center",
      fontSize: width < smBreakpoint ? 34 : width < mdbreakpoint ? 42 : 48,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 38 : width < mdbreakpoint ? 48 : 54,
      textAlign: "center",
    },
    h2: {
      fontSize: width < smBreakpoint ? 28 : width < mdbreakpoint ? 34 : 40,
    },
    h3: {
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 24 : 28,
    },
    pushButton: {
      borderWidth: 1,
      borderColor: theme.foregroundColor,
      borderRadius: 5,
      // padding: 5,
      justifyContent: "center",
      height: 100,
      // width: 222,
      marginHorizontal: width < mdbreakpoint ? 0 : 5,
      marginVertical: 5,
    },
    timeSlot: { width: width < lgBreakpoint ? 140 : 222 },
    item: { width: 222 },
  });

  type Experience = "Wheel" | "HB" | "OW";
  type Time = "1" | "4" | "7" | "10";
  type Day = "8/23" | "8/24" | "8/25" | "8/30" | "8/31" | "9/1" | "9/6" | "9/7" | "9/8" | "9/13" | "9/14" | "9/15" | "9/20" | "9/21" | "9/22" | "9/27" | "9/28" | "9/29";
  type Slot = { time: Time; day: Day };
  type PaymentLink = {
    experience: Experience;
    // time: Time;
    // day: Day;
    slot: Slot;
    paymentLink: string;
    price: string;
    isAvailable?: boolean;
  };



  const [selectedExperience, setSelectedExperience] = useState<Experience>("Wheel");

  const soldOutTimeSlots: Slot[] = [
  ];

  const containsObject = (obj: Slot, list: Slot[]) => {
    return list.some((e) => JSON.stringify(e) === JSON.stringify(obj));
  };

  const data = [
    {
      product: "Wheel",
      name: "Wheel Throwing Class",
      description:
        "This low-key workout is a full body exercise in calming the mind and controlling the hands. Harness centrifugal force to squeeze, lift and shape a mound of  clay into the vessel of your dreams. If you're new to this, the objective is to make as many pots as you can in 2 hours. You'll get better with time. Good for: pots, cups, bowls, small vases, and ashtrays with uniform shape.",
      id: "wheeeeel",
      assets: [
        {
          id: "w1",
          url: "",
          height: 100,
          width: 100,
          caption: "",
        },
      ],
    },
    {
      product: "HB",
      name: "Hand Building Class",
      description:
        "A more controlled approach to pottery, hand building is like table-top sculpting. Use your hands, carving tools, and coiling techniques to shape the clay into anything you want. Good for: ashtrays, figurines, tiles, and cups with intricate details.",
      id: "hand",
      assets: [
        {
          id: "h1",
          url: "",
          height: 100,
          width: 100,
          caption: "",
        },
      ],
    },
    {
      product: "OW",
      name: "Open Wheel Session",
      description:
        "Designed for those who have taken our Wheel Class and want to get more practice time in. This is an uninstructed, two-hour time slot in The Pottery to get your reps in. This session only includes 5lbs of clay - firing services & more clay available in the studio for an additional fee. You must take our Wheel Experience class first, regardless of previous experience!",
      id: "open",
      assets: [
        {
          id: "h1",
          url: "",
          height: 100,
          width: 100,
          caption: "",
        },
      ],
    },
  ];

  const paymentLinks: PaymentLink[] = [

    {
      experience: "Wheel",
      slot: { day: "9/13", time: "4" },
      paymentLink: "https://buy.stripe.com/14k3fS0iwfKK5QA7mx",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "9/13", time: "7" },
      paymentLink: "https://buy.stripe.com/aEUdUwe9m1TU0wg4am",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "9/14", time: "1" },
      paymentLink: "https://buy.stripe.com/3csdUwaXaeGG4Mw9uH",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "9/14", time: "4" },
      paymentLink: "https://buy.stripe.com/fZecQs7KY1TU5QAbCQ",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/14", time: "7" },
      paymentLink: "https://buy.stripe.com/4gwbMo8P20PQ1Ak8qF",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/15", time: "10" },
      paymentLink: "https://buy.stripe.com/dR67w87KY7ee4Mw6iy",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/15", time: "1" },
      paymentLink: "https://buy.stripe.com/fZe8Ac8P28iien636n",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/15", time: "4" },
      paymentLink: "https://buy.stripe.com/14k03G1mAfKK5QAayQ",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "9/13", time: "4" },
      paymentLink: "https://buy.stripe.com/dR6cQs4yMeGGfraazf",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "9/13", time: "7" },
      paymentLink: "https://buy.stripe.com/cN2bMoghubuu7YI7n4",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "9/14", time: "1" },
      paymentLink: "https://buy.stripe.com/bIYbMoaXaeGGa6Q9vd",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/14", time: "4" },
      paymentLink: "https://buy.stripe.com/3cs6s41mAeGG4MwfTC",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/14", time: "7" },
      paymentLink: "https://buy.stripe.com/aEU8Ace9mbuu6UE36R",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/15", time: "10" },
      paymentLink: "https://buy.stripe.com/eVa4jWaXafKKen66j4",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/15", time: "1" },
      paymentLink: "https://buy.stripe.com/9AQ6s48P26aa1AkfTF",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/15", time: "4" },
      paymentLink: "https://buy.stripe.com/6oEeYA6GUeGG92M0YM",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "9/14", time: "10" },
      paymentLink: "https://buy.stripe.com/14k6s47KY2XY0wg0Z5",
      price: "$55",
      isAvailable: false,
    },
    {
      experience: "OW",
      slot: { day: "9/15", time: "7" },
      paymentLink: "https://buy.stripe.com/fZe9EgaXabuu92M4bi",
      price: "$55",
      isAvailable: true,
    },

    {
      experience: "Wheel",
      slot: { day: "9/20", time: "4" },
      paymentLink: "https://buy.stripe.com/cN23fS1mAaqq1AkfTb",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/20", time: "7" },
      paymentLink: "https://buy.stripe.com/4gwcQs6GU9mmceY6iC",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/21", time: "1" },
      paymentLink: "https://buy.stripe.com/9AQg2E4yM1TUdj2ayT",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/21", time: "4" },
      paymentLink: "https://buy.stripe.com/00g8Ac2qEdCC7YI6iE",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/21", time: "7" },
      paymentLink: "https://buy.stripe.com/28oaIkd5i6aa5QA0Yl",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/22", time: "10" },
      paymentLink: "https://buy.stripe.com/bIYeYA8P2eGGbaU36u",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/22", time: "1" },
      paymentLink: "https://buy.stripe.com/8wM8Ac9T6buu2EogXl",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/22", time: "4" },
      paymentLink: "https://buy.stripe.com/cN2aIk3uI6aagvegXm",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "9/20", time: "4" },
      paymentLink: "https://buy.stripe.com/9AQ8Ace9m4227YI22R",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/20", time: "7" },
      paymentLink: "https://buy.stripe.com/00g8Ac2qE6aa6UEdLA",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/21", time: "1" },
      paymentLink: "https://buy.stripe.com/bIY17K5CQaqq92M7nd",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/21", time: "4" },
      paymentLink: "https://buy.stripe.com/8wM3fS9T6buu4Mw22U",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/21", time: "7" },
      paymentLink: "https://buy.stripe.com/fZe3fS6GUeGG0wgePH",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/22", time: "10" },
      paymentLink: "https://buy.stripe.com/fZedUwfdqdCCbaUgXQ",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/22", time: "1" },
      paymentLink: "https://buy.stripe.com/aEU4jWe9m7eea6QgXR",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/22", time: "4" },
      paymentLink: "https://buy.stripe.com/eVa9Eg2qE9mm92MePK",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "9/21", time: "10" },
      paymentLink: "https://buy.stripe.com/fZe7w83uIcyygve0Z7",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "OW",
      slot: { day: "9/22", time: "7" },
      paymentLink: "https://buy.stripe.com/8wMaIk6GUgOO92M23c",
      price: "$55",
      isAvailable: true,
    },

    {
      experience: "Wheel",
      slot: { day: "9/27", time: "4" },
      paymentLink: "https://buy.stripe.com/14kbMoghugOO7YI36x",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/27", time: "7" },
      paymentLink: "https://buy.stripe.com/14k9Eg9T60PQ4MwePg",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/28", time: "1" },
      paymentLink: "https://buy.stripe.com/fZe8Acc1e1TUen6fTl",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/28", time: "4" },
      paymentLink: "https://buy.stripe.com/aEU4jWaXa6aaen69uY",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/28", time: "7" },
      paymentLink: "https://buy.stripe.com/dR6cQs5CQ4225QA0Yt",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/29", time: "10" },
      paymentLink: "https://buy.stripe.com/8wM9Eg4yM8iibaU8qW",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/29", time: "1" },
      paymentLink: "https://buy.stripe.com/8wM2bOe9m8ii5QA0Yv",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "9/29", time: "4" },
      paymentLink: "https://buy.stripe.com/6oE7w80iw6aa2Eo4aI",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "9/27", time: "4" },
      paymentLink: "https://buy.stripe.com/9AQ03Ge9mdCCgvegXT",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/27", time: "7" },
      paymentLink: "https://buy.stripe.com/8wM03Ge9m8ii2Eo6jg",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/28", time: "1" },
      paymentLink: "https://buy.stripe.com/5kAg2E6GU5663Is4b9",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/28", time: "4" },
      paymentLink: "https://buy.stripe.com/fZe6s45CQbuu7YI6ji",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/28", time: "7" },
      paymentLink: "https://buy.stripe.com/14kbMoc1eaqqgve7nn",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/29", time: "10" },
      paymentLink: "https://buy.stripe.com/aEU9Eg2qEeGG3IsazA",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/29", time: "1" },
      paymentLink: "https://buy.stripe.com/fZeg2E1mA4226UEgXZ",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "9/29", time: "4" },
      paymentLink: "https://buy.stripe.com/5kAdUw0iwaqqbaUbDG",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "9/28", time: "10" },
      paymentLink: "https://buy.stripe.com/fZe7w8ghuaqqen6cHR",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "OW",
      slot: { day: "9/29", time: "7" },
      paymentLink: "https://buy.stripe.com/8wMbMo6GU9mm4MwazK",
      price: "$55",
      isAvailable: true,
    },
  ];

  const firstAvailWheel = paymentLinks.filter(l => l.experience === 'Wheel' && l.isAvailable === true)[0].slot
  const [selectedTime, setSelectedTime] = useState<Time>(firstAvailWheel.time);
  const [selectedDay, setSelectedDay] = useState<Day>(firstAvailWheel.day);

  interface TimeSlotProps {
    day: Day;
    time: Time;
    slot: Slot;
    isAvailable?: boolean;
  }

  const TimeSlot = ({ day, time, slot, isAvailable = true }: TimeSlotProps) => (
    <TouchableOpacity
      disabled={!isAvailable || containsObject(slot, soldOutTimeSlots)}
      style={{ margin: 5, maxWidth: '45%' }}
      onPress={() => {
        setSelectedDay(day);
        setSelectedTime(time);
      }}
    >
      <View
        style={[
          styles.pushButton,
          styles.timeSlot,
          {
            opacity: isAvailable === false ? 0.5 : 1,
            backgroundColor:
              selectedDay === day && selectedTime === time
                ? theme.foregroundColor
                : theme.backgroundColor,
          },
        ]}
      >
        <P
          style={[
            styles.text,
            styles.body,
            {
              color:
                selectedDay === day && selectedTime === time
                  ? theme.backgroundColor
                  : theme.foregroundColor,
              textAlign: 'center',
            },
          ]}
        >
          {containsObject(slot, soldOutTimeSlots) || isAvailable === false
            ? 'Sold Out'
            : `${time}:00`}
          {/* {!isAvailable} */}
        </P>
      </View>
    </TouchableOpacity>
  );

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <CuratorHeader />
        <View style={{ height: width < mdbreakpoint ? 20 : 40 }} />
        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { marginBottom: 0 }]}>
            The Pottery at Happy Medium
          </H1>
          <P
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                marginTop: 0,
                letterSpacing: 1.2,
                marginBottom: 0,
                width: 280,
                textAlign: 'center',
              },
            ]}
          >
            open every {'\n'} Friday, Saturday & Sunday{'\n'}
          </P>
          <Text
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: 10,
                letterSpacing: 1,
                fontWeight: 'bold',
                fontSize: 16,
              },
            ]}
          >
            49 Market St{"\n"}Ground Floor{"\n"}NY, NY 10002
          </Text>
          {/* <P style={[styles.text, styles.body]}>
            Back soon with info and tickets! Leave your number below to be
            the first to know when info and tickets are released :)
          </P>
          <View style={{ alignSelf: 'center' }}>
            <PhoneNumberSignupForm />
          </View> */}
          <View style={{ height: 20 }} />

          <P style={[styles.text, styles.body]}>
            The Pottery is a beginner-friendly pottery studio on the first floor of Happy Medium's 49 Market St. location offering wheel throwing and hand-building classes to everyone 16 & up.{"\n\n"}Classes are 2 hours long and each ticket includes  admission for one person, a 20 minute demo + 1:1 instruction throughout, unlimited clay, one fully glaze-fired pot, and all cleanup. If you fall in love with more than one of your pieces, additional pieces are available to purchase for $25.
          </P>
          <P style={[styles.text, styles.body]}>
            We also offer additional services to fully customize your pot and get it home to you safely. Each add on is $25 and can be purchased after your class.
            <LI>
              <B>Custom design: </B>want to do something custom? Come back to the Art Cafe and paint your pot! Appointments are available once your piece is bisque fired (2-3 weeks after your class) and include unlimited glaze and 2 hours in the Art Cafe on the second floor.
            </LI>
            <LI>
              <B>Just make it a candle: </B>love your piece, but not quite sure what to do with it? Just make it a candle. Choose your scent after your class and we will hand-pour it for you before you pick it up!
            </LI>
            <LI>
              <B>Shipping: </B>Don't live in NYC? No problem. We offer shipping all around the world. US shipping is $25 and international starts at $40.
            </LI>
          </P>
          <P style={[styles.text, styles.body]}>
            Open Wheel sessions are our version of a “build-your-own” membership. They're available to anyone who has taken our wheel throwing classes and include 5lbs of clay and 2 hours on the wheel. Additional clay and firing services are available for purchase at the studio.
          </P>
          <P style={[styles.text, styles.body]}>
            Processing time for pottery is 3-4 weeks and available for pickup on Thursdays from 5:30pm-9:30pm, Fridays from 4-9pm, Saturdays from 10am-9pm and Sundays from 10am-6pm on the first floor of our studio.
          </P>
          <P style={[styles.text, styles.body, { fontSize: 16, fontWeight: 'bold' }]}>
            **Please note, our tickets are non-refundable. You must notify us at pottery@happy-medium.co at least 48 hours before your time slot in order to be eligible to reschedule. Otherwise, the full price will be forfeit. If you purchase your ticket within 48 hours of the start time it is not eligible to be rescheduled.**
          </P>
          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 1</H2>
          <P
            style={[
              styles.text,
              styles.body,
              { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
            ]}
          >
            Choose your experience
          </P>
          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'center',
              width: '100%',
              alignContent: 'center',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedExperience('Wheel')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedExperience === 'Wheel'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedExperience === 'Wheel'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Wheel Throwing
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedExperience('HB')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedExperience === 'HB'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedExperience === 'HB'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Hand Building
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedExperience('OW')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedExperience === 'OW'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedExperience === 'OW'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Open Wheel
                </P>
              </View>
            </TouchableOpacity>

          </View>
        </View>
        <View style={{ height: 20 }} />

        {/* <ImageCarousel assets={data.filter(i => i.product === selectedDesign)?.flatMap(i => i.assets)} /> */}

        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { alignSelf: 'flex-start', marginBottom: 0 }]}>
            {data.find((i) => i.product === selectedExperience)?.name}
          </H1>
          <P style={[styles.text, styles.body]}>{data.find((i) => i.product === selectedExperience)?.description}</P>
          {/* <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 2</H2>
        <P
          style={[
            styles.text,
            styles.body,
            { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
          ]}
        >
          Choose your wood
        </P>
          <View style={{flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
        <TouchableOpacity style={{margin: 5}} onPress={() => setSelectedWood('Douglas Fir')}>
            <View
              style={{
                borderWidth: 1,
                borderColor: theme.foregroundColor,
                backgroundColor:
                  selectedWood === 'Douglas Fir'
                    ? theme.foregroundColor
                    : theme.backgroundColor,
                borderRadius: 5,
                padding: 10,
                width: 140
              }}
            >
              <P
                style={[
                  styles.text,
                  styles.body,
                  {
                    color:
                      selectedWood === 'Douglas Fir'
                        ? theme.backgroundColor
                        : theme.foregroundColor,
                    textAlign: 'center'
                  },
                ]}
              >
                Douglas Fir{'\n'}
                <Text style={[styles.text, styles.body, {fontSize: 12, color:
                      selectedWood === 'Douglas Fir'
                        ? theme.backgroundColor
                        : theme.foregroundColor,}]}>(light)</Text>
              </P>
            </View>
          </TouchableOpacity>
        <TouchableOpacity style={{margin: 5}} onPress={() => setSelectedWood('Mahogany')}>
            <View
              style={{
                borderWidth: 1,
                borderColor: theme.foregroundColor,
                backgroundColor:
                  selectedWood === 'Mahogany'
                    ? theme.foregroundColor
                    : theme.backgroundColor,
                borderRadius: 5,
                padding: 10,
                width: 140
              }}
            >
              <P
                style={[
                  styles.text,
                  styles.body,
                  {
                    color:
                      selectedWood === 'Mahogany'
                        ? theme.backgroundColor
                        : theme.foregroundColor,
                    textAlign: 'center'
                  },
                ]}
              >
                Mahogany{'\n'}
                <Text style={[styles.text, styles.body, {fontSize: 12, color:
                      selectedWood === 'Mahogany'
                        ? theme.backgroundColor
                        : theme.foregroundColor,}]}>(dark)</Text>
              </P>
            </View>
          </TouchableOpacity> */}
        </View>
        <View style={{ height: 60 }} />
        <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 2</H2>
        <P
          style={[
            styles.text,
            styles.body,
            { textAlign: 'center', alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
          ]}
        >
          Choose your day and time{'\n'}
          <Text style={[styles.text, styles.body, { fontSize: 12 }]}>(each experience lasts up to 2 hours)</Text>
        </P>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 9/13</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/13').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 9/14</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/14').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 9/15</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/15').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 9/20</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/20').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 9/21</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/21').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 9/22</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/22').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 9/27</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/27').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 9/28</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/28').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 9/29</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '9/29').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>


        {/* </View> */}
        <View style={{ height: 40 }} />


        <View style={{ height: 60 }} />
        {/* </View> */}
      </ScrollView>

      <TouchableOpacity disabled={false} style={{}} onPress={() => window.open(paymentLinks.find(l => l.experience === selectedExperience && l.slot.day === selectedDay && l.slot.time === selectedTime)?.paymentLink, '__blank')}>
        <View style={{ position: 'absolute', bottom: 0, right: width < mdbreakpoint ? undefined : 0, backgroundColor: theme.foregroundColor, borderRadius: 50, flexDirection: 'row', justifyContent: 'space-between', padding: 15, paddingHorizontal: 60, margin: 20, width: 320, alignSelf: 'center', borderWidth: 1, borderColor: theme.backgroundColor }}>
          {/* <Text style={[styles.text, styles.h3, {color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1}]}>Reserve Now</Text> */}

          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Checkout</Text>
          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>{paymentLinks.find(l => l.experience === selectedExperience && l.slot.day === selectedDay && l.slot.time === selectedTime)?.price}</Text>
        </View>
      </TouchableOpacity>
    </>

  );
};
